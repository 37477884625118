import React from "react"
import stCss from '../../st-css';
import {variant, styleProps, sizeShorthandProps, textShorthandProps, paddingShorthandProps} from 'st-css';
import { spacingShorthandProps } from "st-css";

export const OverlayStyled = stCss.canonize('div')((p) => ({
  zIndex: 0,
  visibility: p.open ? 'visible' : 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,.25)',
  backdropFilter: 'blur(10px)',
  webkitBackdropFilter: 'blur(10px)',
  transition: 'opacity .2s linear',
  opacity: p.open ? 1 : 0
}),
  styleProps(sizeShorthandProps, textShorthandProps, paddingShorthandProps, spacingShorthandProps),
);

export const Overlay = ({children, ...props}) => (
    <OverlayStyled {...props}>
      {children}
    </OverlayStyled>
);