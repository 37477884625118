import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const Meta = ({ title, titleTemplate, description }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const { siteMetadata } = site;

  return (
    <Helmet htmlAttributes={{ lang: `en` }} titleTemplate={titleTemplate || `%s | ${siteMetadata.title}`}>
      <title>{title}</title>
      <meta name="description" content={description || siteMetadata.description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description || siteMetadata.description} />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content={siteMetadata.title} />
    </Helmet>
  );
};
